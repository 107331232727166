import '../../style/style.css'
import { Link } from 'react-router-dom';

function Cablogcad(){
    return(
        <div className="pai_logincad">
            <div className='cab_logincad'>
                <Link className='return_cablogecad' to="/"><h3>SANGUE BOM</h3></Link>
            </div>
        </div>
    )
}
export default Cablogcad;